import React, { useState } from "react";
import { NavBar, Button } from "./Nav.style";
import Search from "./Search";
import Manage from "./Manage";
import { BiLocationPlus as Icon } from "react-icons/bi";
import { IoClose as Close } from "react-icons/io5";

const Nav = (props) => {
  const [address, setAddress] = useState("");

  const clearForm = () => {
    setAddress("");
  };

  return (
    <NavBar>
      <Button id="getUserLocation" onClick={props.getUserLocation}>
        <Icon />
      </Button>
      <Search
        setLat={props.setLat}
        setLong={props.setLong}
        address={address}
        setAddress={setAddress}
      />
      {address !== "" && (
        <Button id="clearForm" onClick={clearForm}>
          <Close />
        </Button>
      )}
      <Manage manage={props.manage} />
    </NavBar>
  );
};

export default Nav;
