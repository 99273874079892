import styled from "styled-components";

const media = {
  mobile: "@media(max-width:375px)",
};

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-width: 16em;
  max-width: 30em;
  height: 3.5em;
  padding: 0.5em 2em;
  border-radius: 0.5em;
  margin: 1em auto;
  background-color: #260648a6;
  color: #fff;
  letter-spacing: 1px;

  ${media.mobile} {
    padding: 0.5em 1em;
  }

  h4 {
    display: flex;
    align-items: center;
    font-weight: 400;

    svg {
      margin-right: 0.5em;
      font-size: 1em;
    }
  }
`;
