import styled from "styled-components";

export const Container = styled.div`
  max-width: 30em;
  min-width: 16em;
  padding: 0.5em 1em;
  border-radius: 0.5em;
  margin: 4em auto 2em;
  background-color: rgba(0, 0, 0, 0.6);
  position: relative;

  h1 {
    margin-bottom: 0.1em;
    font-weight: 600;
    letter-spacing: 1px;
    text-transform: capitalize;
  }

  h4 {
    margin-bottom: 0.5em;
    font-weight: normal;
    letter-spacing: 1px;

    sup {
      svg {
        font-size: 1.2em;
      }

      p {
        padding: 0.1em 0.5em;
        border-radius: 0.3em;
        border: 2px solid gray;
        position: absolute;
        top: 5.2em;
        background-color: #fff;
        color: #000;
        display: none;
        font-style: italic;

        &.display {
          display: block;
        }
      }
    }
  }

  h2 {
    margin-bottom: 0.1em;
    font-weight: 400;
    letter-spacing: 1px;
  }
`;
