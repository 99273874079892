import React, { useState } from "react";
import { GoSettings as Icon } from "react-icons/go";
import { Button, FormContainer } from "./Nav.style";

const Manage = (props) => {
  const [display, setDisplay] = useState(false);
  const [shafaqDisplay, setShafaqDisplay] = useState(false);

  const handleClick = () => {
    setDisplay(!display);
  };

  const changeMethod = () => {
    let select = document.querySelector("#method");
    let value = select.options[select.selectedIndex].value;
    props.manage.setMethod(value);

    if (Number(value) === 15) {
      setShafaqDisplay(true);
    }
    if (Number(value) !== 15) {
      setShafaqDisplay(false);
      props.manage.setShafaq("");
    }
  };

  const changeShafaq = () => {
    let select = document.querySelector("#shafaq");
    let value = select.options[select.selectedIndex].value;

    props.manage.setShafaq(value);
  };

  const changeSchool = () => {
    let select = document.querySelector("#school");
    let value = select.options[select.selectedIndex].value;
    props.manage.setSchool(value);
  };

  const changeMidnightMode = () => {
    let select = document.querySelector("#midnightMode");
    let value = select.options[select.selectedIndex].value;
    props.manage.setMidnightMode(value);
  };

  const changeLatitudeMethod = () => {
    let select = document.querySelector("#latitudeMethod");
    let value = select.options[select.selectedIndex].value;
    props.manage.setLatitudeMethod(value);
  };

  return (
    <>
      <Button onClick={handleClick}>
        <Icon />
      </Button>
      <FormContainer className={display ? "display" : ""}>
        <div>
          <select name="method" id="method" onChange={changeMethod}>
            <option value="">Select Method</option>
            <option value="0">Shia Ithna-Ansari</option>
            <option value="1">University of Islamic Sciences, Karachi</option>
            <option value="2">Islamic Society of North America</option>
            <option value="3">Muslim World League</option>
            <option value="4">Umm Al-Qura University, Makkah</option>
            <option value="5">Egyptian General Authority of Survey</option>
            <option value="7">
              Institute of Geophysics, University of Tehran
            </option>
            <option value="8">Gulf Region</option>
            <option value="9">Kuwait</option>
            <option value="10">Qatar</option>
            <option value="11">Majlis Ugama Islam Singapura, Singapore</option>
            <option value="12">Union Organization islamic de France</option>
            <option value="13">Diyanet İşleri Başkanlığı, Turkey</option>
            <option value="14">
              Spiritual Administration of Muslims of Russia
            </option>
            <option value="15">
              Moonsighting Committee Worldwide (select shafaq too)
            </option>
          </select>

          {shafaqDisplay && (
            <select name="shafaq" id="shafaq" onChange={changeShafaq}>
              <option value="">Select Shafaq</option>
              <option value="general">General</option>
              <option value="ahmer">Ahmer</option>
              <option value="abyad">Abyad</option>
            </select>
          )}

          <select name="school" id="school" onChange={changeSchool}>
            <option value="">Select School</option>
            <option value="0">Shafi</option>
            <option value="1">Hanafi</option>
          </select>

          <select
            name="midnightMode"
            id="midnightMode"
            onChange={changeMidnightMode}
          >
            <option value="">Select Midnight Mode</option>
            <option value="0">Standard (Mid Sunset to Sunrise)</option>
            <option value="1">Jafari (Mid Sunset to Fajr)</option>
          </select>

          <select
            name="latitudeMethod"
            id="latitudeMethod"
            onChange={changeLatitudeMethod}
          >
            <option value="">Select Latitude Method</option>
            <option value="1">Middle of the Night</option>
            <option value="2">One Seventh</option>
            <option value="3">Angle Based</option>
          </select>
        </div>
      </FormContainer>
    </>
  );
};

export default Manage;
