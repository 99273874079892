import React from "react";
import { FaRegClock as ClockIcon } from "react-icons/fa";
import { Container } from "./Display.style";

const Display = (props) => {
  const formatedTime = (time) => {
    let getHours = (Number(time.slice(0, 2)) % 12).toString();

    let hours = ("0" + (getHours === "0" ? 12 : getHours)).slice(-2);
    let minutes = ("0" + time.slice(3, 5)).slice(-2);
    let ampm = time.slice(0, 2) >= 12 ? "PM" : "AM";

    return `${hours} : ${minutes} ${ampm}`;
  };

  return (
    <Container>
      <h3>{props.namaz}</h3>
      <h4>
        <ClockIcon /> {formatedTime(props.start)}
      </h4>
    </Container>
  );
};

export default Display;
