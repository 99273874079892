import React from "react";
import Display from "./Display";
import { Container } from "../Title/Title.style";

const DisplayContent = ({ data }) => {
  return (
    <>
      <Display namaz="Fajr" start={data.Fajr} />
      <Display namaz="Sunrise" start={data.Sunrise} />
      <Display namaz="Dhuhr" start={data.Dhuhr} />
      <Display namaz="Asr" start={data.Asr} />
      <Display namaz="Maghrib" start={data.Maghrib} />
      <Display namaz="Eisha" start={data.Isha} />

      <Container>
        <h1 style={{ textAlign: "center" }}>Other</h1>
      </Container>

      <Display namaz="Midnight" start={data.Midnight} />
      <Display namaz="Shuhur End" start={data.Imsak} />
      <Display namaz="Iftar Time" start={data.Sunset} />
    </>
  );
};

export default DisplayContent;
