import React, { useState } from "react";
import { Container } from "./Title.style";
import { TiInfoLarge as Info } from "react-icons/ti";

const Title = (props) => {
  const [display, setDisplay] = useState(false);

  const displayTrue = () => {
    setDisplay(true);
  };

  const displayFalse = () => {
    setDisplay(false);
  };

  const getTime = () => {
    const date = new Date();

    let hours = ("0" + (date.getHours() % 12)).slice(-2);
    let minutes = ("0" + date.getMinutes()).slice(-2);
    let seconds = ("0" + date.getSeconds()).slice(-2);
    let ampm = date.getHours() >= 12 ? "PM" : "AM";

    let formatedTime = `${hours} : ${minutes} : ${seconds} ${ampm}`;
    return formatedTime;
  };

  const [currtime, setCurrtime] = useState(getTime());

  setInterval(() => {
    setCurrtime(getTime());
  }, 1000);

  return (
    <Container>
      <h1>{props.location}</h1>
      <h4>
        {props.timings.gregorian.month.en} {props.timings.gregorian.day},{" "}
        {props.timings.gregorian.year}
        <br />
        {props.timings.hijri.month.en} {props.timings.hijri.day},{" "}
        {props.timings.hijri.year}
        <sup onMouseOver={displayTrue} onMouseLeave={displayFalse}>
          <Info />
          <p className={display ? "display" : ""}>
            Date will be updated at 12:00 am (local time)
          </p>
        </sup>
      </h4>
      <h2>{currtime}</h2>
    </Container>
  );
};

export default Title;
