import styled from "styled-components";

export const NavBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 20em;
  width: 100%;
  min-width: 10.6em;
  height: 2.5em;
  padding: 0.5em 1em;
  border-radius: 0.5em;
  margin: 1em auto;
  background-color: rgba(0, 0, 0, 0.6);
  font-size: 1.5em;
  text-align: end;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-right: 0.5em;
  position: relative;
`;

export const Input = styled.input`
  padding: 0.1em 0.5em;
  padding-left: 1.2em;
  width: 100%;
  max-width: 18em;
  min-width: 8em;
  border: none;
  border-bottom: 1px solid #fff;
  background-color: transparent;
  color: #fff;
  outline: none;
  font-size: 1em;
`;

export const AutoComplete = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  text-align: left;
  position: absolute;
  top: 1.6em;
  z-index: 10;
  border-radius: 0 0 0.5em 0.5em;
  background-color: rgba(0, 0, 0, 1);

  > div {
    padding: 0.5em 1em;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    color: #fff;
    border-radius: 0 0 0.5em 0.5em;
    font-size: 0.75em;
    cursor: pointer;

    &:hover,
    &:focus {
      opacity: 0.8;
    }
  }
`;

export const Button = styled.button`
  border: none;
  outline: none;
  background-color: transparent;
  color: #fff;
  font-size: 1.2em;
  margin-top: 0.3em;

  &#getUserLocation,
  &#clearForm {
    position: relative;
    z-index: 100;

    svg {
      position: absolute;
      left: -0.1em;
      top: -0.75em;
    }
  }

  &#clearForm {
    svg {
      left: -1.3em;
      top: -0.65em;
      font-size: 1.1em;
    }
  }

  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }

  &:active {
    opacity: 0.6;
  }
`;

export const FormContainer = styled.div`
  display: none;
  z-index: 100;

  &.display {
    display: block;
  }

  div {
    display: flex;
    flex-direction: column;
    max-width: 20em;
    min-width: 10em;
    padding: 1em;
    width: 90vw;
    margin: auto;
    border-radius: 0.5em;
    position: absolute;
    top: 3.82em;
    right: 0;
    left: 0;
    background: #0c0123;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
      0 2px 4px -2px rgba(0, 0, 0, 0.1);

    select {
      padding: 0.5em 0.2em 0.3em;
      margin: 0.5em 0;
      font-size: 0.7em;
      background-color: transparent;
      color: #fff;

      option {
        color: #fff;
        background-color: rgba(0, 0, 0, 0.9);
      }

      &:focus {
        outline: none;
      }
    }
  }
`;
