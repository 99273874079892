import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: center;
  width: 100vw;
  height: 100vh;
`;

export const Wrapper = styled.div`
  margin: 0.7rem;
`;
