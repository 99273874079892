import React from "react";
import Nav from "../Nav/Nav.js";
import { Container, Wrapper } from "./Home.Style.js";

const Welcome = (props) => {
  return (
    <Container>
      <Wrapper>
        <Nav
          setLat={props.setLat}
          setLong={props.setLong}
          getUserLocation={props.getUserLocation}
          manage={props.manage}
        />
      </Wrapper>
    </Container>
  );
};

export default Welcome;
