import React from "react";
import { Container, Input, AutoComplete } from "./Nav.style";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";

const Search = (props) => {
  const handleSelect = async (value) => {
    props.setAddress(value);
    const results = await geocodeByAddress(value);
    const latLng = await getLatLng(results[0]);
    props.setLat(latLng.lat);
    props.setLong(latLng.lng);
  };

  return (
    <>
      <PlacesAutocomplete
        value={props.address}
        onChange={props.setAddress}
        onSelect={handleSelect}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <Container>
            <Input
              {...getInputProps({
                placeholder: "Search City",
              })}
            />
            <AutoComplete>
              {loading && <div>Loading...</div>}
              {suggestions.map((suggestion) => {
                return (
                  <div
                    key={suggestion.placeId}
                    {...getSuggestionItemProps(suggestion)}
                  >
                    {suggestion.description}
                  </div>
                );
              })}
            </AutoComplete>
          </Container>
        )}
      </PlacesAutocomplete>
    </>
  );
};

export default Search;
