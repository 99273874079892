import React from "react";
import Nav from "../Nav/Nav";
import Title from "../Title/Title";
import DisplayContent from "../Display/DisplayContent";
import { Wrapper } from "./Home.Style.js";

const HomeContent = (props) => {
  return (
    <Wrapper>
      <Nav
        setLat={props.setLat}
        setLong={props.setLong}
        getUserLocation={props.getUserLocation}
        manage={props.manage}
      />

      <Title timings={props.data.date} location={props.location} />

      <DisplayContent data={props.data.timings} />
    </Wrapper>
  );
};

export default HomeContent;
