import React, { useEffect, useState } from "react";
import HomeContent from "./HomeContent";
import Welcome from "./Welcome";

const Home = () => {
  const [data, setData] = useState([]);
  const [lat, setLat] = useState();
  const [long, setLong] = useState();
  const [city, setCity] = useState();
  const [country, setCountry] = useState();

  const [method, setMethod] = useState("");
  const [shafaq, setShafaq] = useState("");
  const [school, setSchool] = useState("");
  const [midnightMode, setMidnightMode] = useState("");
  const [latitudeMethod, setLatitudeMethod] = useState("");

  let manage = {
    setMethod,
    setShafaq,
    setSchool,
    setMidnightMode,
    setLatitudeMethod,
    method,
  };

  const getUserLocation = () => {
    navigator.geolocation.getCurrentPosition((position) => {
      setLat(position.coords.latitude);
      setLong(position.coords.longitude);
    });
  };

  useEffect(() => {
    const url = `https://${process.env.REACT_APP_LOCATION_API_URL}/json?latlng=${lat},${long}&key=${process.env.REACT_APP_LOCATION_API_KEY}`;

    lat !== undefined &&
      long !== undefined &&
      fetch(url)
        .then((res) => res.json())
        .then((out) => {
          const address = out.results[1];
          for (let i = 0; i < address.address_components.length; i++) {
            for (
              let j = 0;
              j < address.address_components[i].types.length;
              j++
            ) {
              switch (address.address_components[i].types[j]) {
                case "locality":
                  setCity(address.address_components[i].long_name);
                  break;
                case "country":
                  setCountry(address.address_components[i].long_name);
                  break;
                default:
                  break;
              }
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
  }, [lat, long]);

  useEffect(() => {
    const query = `&method=${method}&shafaq=${shafaq}&school=${school}&midnightMode=${midnightMode}&latitudeAdjustmentMethod=${latitudeMethod}`;

    const url = `https://${process.env.REACT_APP_TIME_API_URL}/timingsByCity?city=${city}&country=${country}${query}/`;

    city !== undefined &&
      country !== undefined &&
      fetch(url)
        .then((res) => res.json())
        .then((out) => {
          setData(out.data);
        })
        .catch((err) => {
          console.log(err);
        });
  }, [city, country, method, shafaq, school, midnightMode, latitudeMethod]);

  if (data.length !== 0) {
    return (
      <HomeContent
        data={data}
        location={`${city}, ${country}`}
        setLat={setLat}
        setLong={setLong}
        getUserLocation={getUserLocation}
        manage={manage}
      />
    );
  } else {
    return (
      <Welcome
        setLat={setLat}
        setLong={setLong}
        getUserLocation={getUserLocation}
        manage={manage}
      />
    );
  }
};

export default Home;
